// PopupDialog.js
import React from "react";
import styled from "styled-components";
import { usePopup } from "./PopupContext"; // Adjust the import path
import DialogContent from "./DialogContent"; // Import the new component

// Import your assets
import backgroundImage from "../../Assets/popup/backgroundImage2.png"; // Adjust the path
import closeIcon from "../../Assets/popup/closeIcon.svg"; // Adjust the path
import topicForKidsCover from "../../Assets/popup/topic-for-kids-cover.png";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Dialog = styled.div`
  position: relative;
  width: 900px;
  height: 600px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 72%;
  height: 100%;
  background-image: url(${topicForKidsCover});
  background-size: cover;
  background-position: center;
  margin-right: 57%;

  @media (max-width: 768px) {
    width: 100%;
    height: 30%;
    margin-right: 0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  background-image: url(${closeIcon});
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  z-index: 10;

  @media (max-width: 768px) {
    top: 15px;
    right: 15px;
  }
`;

const PopupDialog = ({ type }) => {
  const { isOpen, hidePopup } = usePopup();

  const handleClose = () => {
    hidePopup();
    sessionStorage.setItem("popupSeen", "true");
  };

  return isOpen ? (
    <Overlay>
      <Dialog>
        <CloseButton onClick={handleClose} />
        <ImageContainer />
        <DialogContent type={type} hidePopup={hidePopup} />
      </Dialog>
    </Overlay>
  ) : null;
};

export default PopupDialog;
